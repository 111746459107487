<template>
	<div class="container app-body">
		<div>
			<header id="top">
				<a href="#">
					<img src="img/logo.svg" class="header-logo" alt="Ayun!" />
				</a>
			</header>

			<b-nav justified vertical class="sidenav">
				<b-nav-item href="#tawitech">TAWITECH INC.</b-nav-item>
				<b-nav-item href="#consent">Consent</b-nav-item>
				<b-nav-item href="#log">Log Data</b-nav-item>
				<b-nav-item href="#communications">Communications</b-nav-item>
				<b-nav-item href="#cookies">Cookies</b-nav-item>
				<b-nav-item href="#security">Security</b-nav-item>
				<b-nav-item href="#changes">Changes to this Privacy Policy</b-nav-item>
				<b-nav-item href="#contact">Contact Us</b-nav-item>
			</b-nav>
		</div>

		<div class="container">
			<header class="header-title">
				<img src="img/logo.svg" class="logo-small" alt="Ayun!" />
				<h1>Privacy Policy</h1>
			</header>

			<p class="h6" id="tawitech"></p>

			<p>
				<b class="tawitech">TAWITECH INC.</b> (hereinafter referred to as “we”,
				“us”, “ours”, depending on the context) operates
				<a href="https://www.tawitech.com.ph">www.tawitech.com.ph</a>,
				<a href="https://tawitech.ph">www.tawitech.ph</a>, and
				<a href="https://www.ayun.ph">www.ayun.ph</a> (hereinafter called “site”
				or “services”), including all related apps, communications and plug-ins
				or extensions. This page informs you of our policies regarding the
				collection, use and disclosure of Personal Information we receive from
				users of the Site.
			</p>

			<p class="h6" id="consent">Consent</p>

			<p>
				If you use our Services, you automatically consent to the collection,
				use and sharing of your personal data under this Privacy Policy, and you
				also agree to our User Agreement.
			</p>

			<p>
				While using our Site, we may ask you to provide us with certain
				personally identifiable information that can be used to contact or
				identify you. Personally identifiable information may include, but is
				not limited to, your real name, email address, designation, contact
				number and work location. In addition, we also collect steps data given
				you’ve granted us permission to do so. Our app is a business toll and
				will not be used by minors.
			</p>

			<p class="h6" id="log">Log Data</p>

			<p>
				Like many site operators, we collect information that your browser or
				app sends whenever you visit our Site or use our Services ("Log Data").
			</p>

			<p>
				This Log Data may include information such as your computer's Internet
				Protocol ("IP") address, browser type, browser version, the pages of our
				site that you visit, the date and duration of your visit, websites
				through which you linked to our sites and other statistics. If you use
				our Services from a mobile device, that device will send us data about
				your location. While most devices allow you to prevent location data
				from being sent to us, turning off location settings on your device will
				affect the usage of the site and the app.
			</p>

			<p>
				Certain areas of the Sites require registration or a password for
				access. Information obtained from users of these areas may also be used
				for statistical and marketing purposes.
			</p>

			<p>
				Certain proprietary data will be collected, such as shipper name,
				vehicle (plate) number, receiver name and location address, description
				of goods, number of pieces, among others vital information to document
				each business transaction. Our services are dynamic and often introduce
				new features, which may require the collection of new information. If we
				collect materially different personal data or materially change how we
				use your data, we will notify you and may also modify this Privacy
				Policy.
			</p>

			<p>
				In addition, we may use third party services such as Google Analytics
				that collect, monitor and analyze our users’ usage. You can read more
				about google analytics here
				<a href="https://www.google.com/analytics"
					>https://www.google.com/analytics</a
				>
			</p>

			<p class="h6" id="communications">Communications</p>

			<p>
				We may use your Personal Information to contact you with newsletters,
				marketing or promotional materials and other information that will
				inform you of the current updates in our application.
			</p>

			<p>
				We will contact you through email, notice posted on our sites and
				services and other ways, including text messages and push notifications.
				We may also send messages about how to use the services or updates on
				our services.
			</p>

			<p>
				We will not share, sell, transfer or otherwise disseminate your personal
				data to third parties and will not do so in future, unless required by
				law, unless required for the purpose of the contract or unless you have
				given express content to do so.
			</p>

			<p class="h6" id="cookies">Cookies</p>

			<p>
				Cookies are files with small amount of data related to your PC and you,
				the user, specifically, while you visit one of our sites and which may
				include an anonymous unique identifier. Cookies are sent to your browser
				from a web site and stored on your computer's hard drive.
			</p>

			<p>
				Like many sites, we use "cookies" to collect information. You can
				instruct your browser to refuse all cookies or to indicate when a cookie
				is being sent. However, if you do not accept cookies, you may not be
				able to use some portions of our Site.
			</p>

			<p class="h6" id="security">Security</p>

			<p>
				The security of your Personal Information is important to us, but
				remember that no method of transmission over the Internet, or method of
				electronic storage, is 100% secure. While we strive to use commercially
				acceptable means to protect your Personal Information, we cannot
				guarantee its absolute security.
			</p>

			<p class="h6" id="changes">Changes To This Privacy Policy</p>

			<p>
				This Privacy Policy is effective as of 28TH of November 2017 and will
				remain in effect except with respect to any changes in its provisions in
				the future, which will be in effect immediately after being posted on
				this page.
			</p>

			<p>
				We reserve the right to update or change our Privacy Policy at any time
				and you should check this Privacy Policy periodically. Your continued
				use of the Service after we post any modifications to the Privacy Policy
				on this page will constitute your acknowledgment of the modifications
				and your consent to abide and be bound by the modified Privacy Policy.
			</p>

			<p>
				If we make any material changes to this Privacy Policy, we will notify
				you either through the email address you have provided us, or by placing
				a prominent notice on our website.
			</p>

			<p class="h6" id="contact">Contact Us</p>

			<p>If you have any questions about these Terms, please contact us.</p>

			TAWITECH, Inc. Pulo-Diezmo Road, Cabuyao, Laguna
			<ul>
				<li>Phone Number:&nbsp;+63 49 543 0598</li>
				<li>Email address: {{ adminAccount }}</li>
			</ul>

			<div>
				<b-button class="back-to-top" href="#top">
					<i class="fa fa-arrow-up my-back-to-top"></i>
				</b-button>
			</div>
		</div>
		<footer class="footer">
			<p class="h7">&copy; 2021 TAWItech Inc. All rights reserved.</p>
		</footer>
	</div>
</template>

<script>
import config from '@/config/env-constants';

export default {
	name: 'PrivacyPolicy',
	computed: {
		adminAccount() {
			return config.adminAccount.TAWI_SUPPORT;
		},
	},
};
</script>
<style lang="scss">
body {
	position: relative;
	background-color: white;
	min-height: 100%;
	padding: 4%;
}

.header-logo {
	width: 100px;
	height: auto;
	padding-bottom: 5%;
}

.header-title h1 {
	color: #122b91;
	padding-bottom: 2%;
	text-transform: uppercase;
	font-weight: bold;
	text-align: center;
}

.tawitech {
	color: #122b91;
}
p.h6 {
	color: #122b91;
	font-weight: bold;
}

p{
	text-align: justify;
}
a {
	color: #f18f01;
	text-decoration: none;

	a :link {
		text-decoration: none;
	}
}

b-nav:hover {
	color: #122b91;
	text-decoration: none;
	outline: none;
}

.nav-link {
	color: gray;
}
.nav-item :hover {
	color: #f18f01;
}
.nav-item :active {
	color: #122b91;
	font-weight: bold;
}

footer {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: auto;
	background-color: #122b91;
	color: white;
}
p.h7{
	text-align: center;
	padding: 5px 0;
}

.back-to-top {
	offset: 100px;
	font-size: 15px;
	position: fixed;
	width: 50px;
	height: 50px;
	bottom: 55px;
	right: 20px;
	background-color: #f18f01;
	color: white !important;
	border-radius: 50px;
	text-align: center;
	box-shadow: 2px 2px 3px #999;
}
.my-back-to-top {
	margin-top: 10px;
}
.logo-small{
	display: none;
}
@media only screen and (max-width: 600px) {
	body {
		margin: 30px;
		margin-top: -30px;
	}
	h1 {
		font-size: 20px;
	}
	.sidenav {
		display: none;
	}
	.header-logo {
		display: none;
	}
	.back-to-top{
		margin-bottom: 40px;
	}
	.logo-small{
		width: 100px;
		display: block;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 20px;
	}
	.container{
		margin-bottom: 20px;
	}
}
</style>
